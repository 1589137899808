import "./style.scss";
import { isDefined } from "@clipboard-health/util-ts";
import { IonButton, IonDatetime, IonIcon, IonLabel, IonNote } from "@ionic/react";
import { ERROR_COLOR } from "@src/appV2/lib/Theme/constants";
import { Shift } from "@src/lib/interface/src";
import { startOfMinute } from "date-fns";
import { closeCircleSharp } from "ionicons/icons";
import moment from "moment-timezone";

import { useAdjustTimeForMultiDayShifts } from "./useAdjustTimeForMultiDayShifts";
import { useShiftTimeEditUtils } from "./useShiftTimeEditUtils";

type ShiftActionTimeProps = {
  shiftActionTime?: string | undefined;
  title: string;
  isEditable: boolean;
  helperText: string;
  facilityTimeZone: string;
  shift: Shift;
  isTitleError: boolean;
} & (
  | {
      isClearable: false;
      onChange: (value: string) => void;
    }
  | {
      isClearable: true;
      onChange: (value: string | undefined) => void;
    }
);

export const DATE_AND_TIME_PICKER_FORMAT = "h:mm a, MMMM D";
export const TIME_PICKER_FORMAT = "h:mm a";

export function ShiftActionTime(props: ShiftActionTimeProps) {
  const {
    shiftActionTime,
    title,
    isEditable,
    helperText,
    facilityTimeZone,
    shift,
    onChange,
    isClearable,
    isTitleError,
  } = props;

  const { timeEditLimits, dayPartIsEditable } = useShiftTimeEditUtils(shift);

  const { adjustTimeForMultiDayShifts } = useAdjustTimeForMultiDayShifts(shift);

  const shiftTimeViewClass = isEditable ? "view-in-column" : "view-in-row";

  return (
    <div className={`edit-time-component ${shiftTimeViewClass}`}>
      <div className={`shift-time-component ${shiftTimeViewClass}`}>
        <IonLabel className="edit-time-label">
          <span style={{ color: isTitleError ? ERROR_COLOR : "inherit" }}>{title}</span>
        </IonLabel>
        <IonButton
          disabled={!isEditable}
          color="transparent"
          className={`time-selector-button ${shiftTimeViewClass}`}
          aria-label={`Select time for ${title}`}
        >
          <IonDatetime
            title={title}
            displayTimezone={facilityTimeZone}
            hour-cycle="h12"
            className={"time-selector"}
            placeholder="--:--"
            displayFormat={
              dayPartIsEditable && isEditable ? DATE_AND_TIME_PICKER_FORMAT : TIME_PICKER_FORMAT
            }
            min={timeEditLimits.min}
            max={timeEditLimits.max}
            value={shiftActionTime}
            onIonChange={(event) => {
              const time = event.detail.value;

              if (!isDefined(time) || time === "") {
                if (isClearable) {
                  onChange(undefined);
                }
                return;
              }

              const adjustedNewDate = adjustTimeForMultiDayShifts(time);
              const truncatedDate = startOfMinute(new Date(adjustedNewDate));
              onChange(truncatedDate.toISOString());
            }}
            aria-label={`Time picker for ${title}`}
          />
          {isClearable && isEditable && Boolean(shiftActionTime) && (
            <button
              className="clear-time-button"
              onClick={() => onChange(undefined)}
              aria-label={`Clear ${title}`}
            >
              <IonIcon icon={closeCircleSharp} />
            </button>
          )}
        </IonButton>
      </div>
      {!isEditable && helperText && (
        <div className="edit-helper-text-container">
          <IonNote className="edit-helper-text">Edited from </IonNote>
          <IonNote className="edit-helper-text">
            {!helperText.includes("skipped")
              ? moment(helperText.toString()).tz(facilityTimeZone).format("hh:mm a")
              : helperText}
          </IonNote>
        </div>
      )}
    </div>
  );
}
